"use client";
import { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { ShallowLink } from "../Link";

export default function TabBox({
  shallow,
  override,
  tabs = [],
  initial = "0",
  variant,
  noOutline,
  onChange,
  borderRadius = "var(--shape-lg-top)",
  sx
}) {
  const [tab, setTab] = useState(initial);
  const { Component = () => null } = tabs[tab] ?? {};

  return (
    <Box
      sx={{
        overflow: "hidden",
        border: noOutline ? undefined : 1,
        borderRadius: noOutline ? undefined : "var(--shape-sm)",
        borderColor: noOutline ? undefined : "var(--outline-variant)"
      }}
    >
      <Tabs
        value={override || tab}
        scrollButtons="none"
        variant={variant || (noOutline ? "fullWidth" : variant)}
        onChange={(_, tab) => setTab(tab) || onChange?.(tab)}
        sx={{
          borderBottom: 1,
          borderBottomColor: "var(--outline-variant)",
          ...sx
        }}
      >
        {tabs.map(({ label, Icon, iconPosition, href, onClick, sx }, index) => (
          <Tab
            key={index}
            label={label}
            value={index.toString()}
            icon={Icon ? <Icon /> : undefined}
            href={href}
            LinkComponent={shallow ? ShallowLink : undefined}
            onClick={onClick}
            iconPosition={iconPosition}
            sx={{
              color: "var(--secondary-color)",
              transition: "all 200ms var(--motion-easing-standard)",
              typography: { compact: "labelMd", expanded: "labelLg" },
              "&:hover": {
                borderRadius,
                color: "var(--primary-color)",
                bgcolor: theme =>
                  theme.alpha("var(--surface-container-highest)", 0.3)
              },
              ...sx
            }}
          />
        ))}
      </Tabs>
      <Component />
    </Box>
  );
}
